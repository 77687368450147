<template>

    <v-main class="white">
    
      <v-container fluid>	
            <v-sheet
              rounded="lg"
              min-height="568"
              max-width="1300"
              class="center"
            >
            <v-container
              @click="show = !show">
      <v-row dense>
        <v-col cols="12">
          <v-card
            color="#385F73"
            dark
            class="elevation-0"
          >
         
         <v-row dense>
        <v-col
          cols="9"
        >
          <v-card-title class="text-h5">
              Beginn der Vorlesung und weitere allgemeine Informationen
            </v-card-title>

             <v-card-subtitle class="grey--text text--lighten-2">Sehr geehrte Studierende, 
              </v-card-subtitle>


    

        </v-col>
        <v-col cols="3">
         <v-card-text align="right">
            17.10.2022, 09:30 Uhr
           </v-card-text>
             
             <div align="right" class="pr-2">
            <v-btn
                icon
              >
        <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
             </div>
    

   

        </v-col>
      </v-row>

               <v-expand-transition>
         <div v-show="show">

        <v-card-text class="pt-2 pb-2">
          


<p>die Vorlesung "Einführung in das Recht der digitalen Gesellschaft" von Prof. Dr. Heckmann beginnt erst am Dienstag, den 25.10.2022. Sie findet dann grundsätzlich in Präsenz statt (Raum 2370, HS). Bitte beachten Sie immer die aktuellen Informationen auf elaw Learn und (für die Übungen) Moodle.
Ihnen werden im Laufe des Semesters neben den Vorlesungspräsentationen auch vertiefende Dossiers zu den behandelten Themen über Moodle zur Verfügung gestellt. <br>Außerdem wird der Besuch der begleitenden Übung bei Alexander Besner oder Sarah Rachut dringend empfohlen.</p>

<p>Wir freuen uns auf das anstehende Semester mit Ihnen!</p>

<p>Mit freundlichen Grüßen</p>

Fabian Wiedemann
        </v-card-text>

              <v-card-actions class=pt-0>
                <v-spacer></v-spacer>
              <v-btn
                text
                to="/announcements"
              >
                MEHR
              </v-btn>
            </v-card-actions>

      </div>
      </v-expand-transition>

          </v-card >
        </v-col>
       
      </v-row>
    </v-container>

      


            <v-container class="pt-0">
    
              <v-row dense>
                <v-col cols="9">

                            <v-chip-group class="pb-0"
                            active-class="primary--text"
                            mandatory
                          >
                            <v-chip
                            outlined
                            v-model="vl_filter"
                            >
                              Vorlesungen Dienstag 18:30
                            </v-chip>
                            <v-chip
                    
                            outlined
                            v-model="ue1_filter"
                            
                            >
                              Übungen Mittwoch 18:30
                            </v-chip>
                             <v-chip
                    
                            outlined
                            v-model="ue2_filter"
                        
                            >
                              Übungen Donnerstag 18:30
                            </v-chip>
                          </v-chip-group>
           
                  </v-col>
                <v-col cols="3" class="text-right">
                  <v-btn
                    text
                    to="/announcements"
                    class="grey--text text--darken-1"
                  >
                    Alle Ankündigungen
                  </v-btn>
                </v-col>
              </v-row>
      <v-row dense class="mt-0">
        <v-col cols="12">


            <v-card
                v-for="(video, i) in filteredUnits"
                :key="i"
                class="elevation-0 pt-1 pb-2"
                max-height="200"
                
              >
    <div class="d-flex flex-no-wrap">

      

<v-img
      v-if="hover"
      class="white--text align-end"
      max-height="138px"
      max-width="245px"
      src="https://www.onlinesolutionsgroup.de/wp-content/uploads/giphy.gif"
    >
      <v-card-text align="right" class="pb-1 pr-2"><div style="elevation-24">{{ video.duration }}</div></v-card-text>
    </v-img>

   
    <v-img
      v-else
      :src="video.image"
      class="white--text align-end"
      max-height="138px"
      max-width="245px"
    >
      <v-card-text align="right" class="pb-1 pr-2"><div style="elevation-24"></div></v-card-text>
    </v-img>

    <div>
      <v-card-text style="font-size:1.2em; font-weight:400;" class="pt-2 pb-1">{{ video.title }}</v-card-text>
    <v-card-subtitle class="pb-2 pt-0">
      {{ video.subtitle }} {{ video.date }}
    </v-card-subtitle>
    <v-card-text class="text--primary">
      <div>{{ video.description }}</div><div class="mt-2">


        <a v-bind:href="video.locationlink" style="text-decoration: inherit;" target="_blank" >
        <v-btn
        small
        depressed
        color="white"
        class="pl-0"
        >
      <v-icon
        color="grey lighten-1"
        class=""
      >
        mdi-map-marker
      </v-icon><div class="pl-1">{{ video.location }}</div></v-btn></a>


        <a v-if="video.disabled == false" v-bind:href="video.file" style="text-decoration: inherit;" target="_blank" >
       <v-btn
              :loading="loading"
              :disabled="video.disabled"
              small
              depressed
              class="pl-1 ml-5"
              @click="loader = 'loading'"
    ><v-icon
        right
        color="grey darken-2"
      >
        mdi-file-pdf-box
      </v-icon><div class="pl-2">Folien</div></v-btn></a>

  
       

      
      
      
      </div>
    </v-card-text>
    </div>
    </div>
  </v-card>
</v-col>
      </v-row>
    </v-container>
    
              <!--  -->
            </v-sheet>
      </v-container>
    </v-main>
</template>

<script>
  export default {
    data: () => ({
      links: [
        'Vorlesungen & Übungen',
        'Skript',
        'Übungsaufgaben',
      ],
      show: false,
      vl_filter: true,
      ue1_filter: false,
      ue2_filter: false,
      selectedType: 'Vorlesung',
      vorlesungen: [
        {
          title: '1. Vorlesung',
          subtitle: '',
          date: '25.10.2022 18:30 Uhr',
          description: 'Organisatorisches, Einführung: Begriff und Funktionen des Rechts',
          duration: '1:30:00',
          type: 'Vorlesung',
          image: 'https://www.tum-cdps.de/wp-content/uploads/lecturehall.jpg',
          file: 'https://www.tum-cdps.de/wp-content/uploads/VL1.pdf',
          disabled: false,
          location: "Hörsaal 2370",
          locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes"
        },
        {
          title: '2. Vorlesung',
          subtitle: '',
          date: '08.11.2022  18:30 Uhr',
          description: 'Grundlagen: Rechtsquellen, Rechtsgebiete, Normenhierarchie, rechtswissenschaftliche Methode',
          duration: '1:30:00',
          type: 'Vorlesung',
          image: 'https://www.tum-cdps.de/wp-content/uploads/lecturehall.jpg',
          disabled: true,
          file: 'https://www.tum-cdps.de/wp-content/uploads/VL2.pdf',
          location: "Hörsaal 2370",
          locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes"
        },
        {
          title: '3. Vorlesung',
          subtitle: '',
          date: '15.11.2022  18:30 Uhr',
          description: 'Wirkungsfelder des Rechts',
          duration: '1:30:00',
          type: 'Vorlesung',
          image: 'https://www.tum-cdps.de/wp-content/uploads/lecturehall.jpg',
          disabled: true,
          file: 'https://www.tum-cdps.de/wp-content/uploads/VL3.pdf',
          location: "Hörsaal 2370",
          locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes"
        },
        {
          title: '4. Vorlesung',
          subtitle: '',
          date: '22.11.2022  18:30 Uhr',
          description: 'Staatsrecht: Grundlagen und Staatsorganisationsrecht',
          duration: '1:30:00',
          type: 'Vorlesung',
          image: 'https://www.tum-cdps.de/wp-content/uploads/lecturehall.jpg',
          disabled: true,
          file: 'https://www.tum-cdps.de/wp-content/uploads/VL4.pdf',
          location: "Hörsaal 2370",
          locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes"
        },
        {
          title: '5. Vorlesung',
          subtitle: '',
          date: '29.11.2022 18:30 Uhr',
          description: 'Staatsrecht: Staatsorganisationsrecht und verfassungsrechtliche Rechtsbehelfe',
          duration: '1:30:00',
          type: 'Vorlesung',
          image: 'https://www.tum-cdps.de/wp-content/uploads/lecturehall.jpg',
          disabled: true,
          file: 'https://www.tum-cdps.de/wp-content/uploads/VL5.pdf',
          location: "Hörsaal 2370",
          locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes"
        },
        {
          title: '6. Vorlesung',
          subtitle: '',
          date: '06.12.2022 18:30 Uhr',
          description: 'Staatsrecht: Grundrechte (Schwerpunkt)',
          duration: '1:30:00',
          type: 'Vorlesung',
          image: 'https://www.tum-cdps.de/wp-content/uploads/lecturehall.jpg',
          disabled: true,
          file: 'https://www.tum-cdps.de/wp-content/uploads/VL6.pdf',
          location: "Hörsaal 2370",
          locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes"
        },
        {
          title: '7. Vorlesung',
          subtitle: '',
          date: '13.12.2022 18:30 Uhr',
          description: 'Staatsrecht: Grundrechte (Schwerpunkt)',
          duration: '1:30:00',
          type: 'Vorlesung',
          image: 'https://www.tum-cdps.de/wp-content/uploads/lecturehall.jpg',
          disabled: true,
          file: 'https://www.tum-cdps.de/wp-content/uploads/VL7.pdf',
          location: "Hörsaal 2370",
          locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes"
        },
        {
          title: '8. Vorlesung',
          subtitle: '',
          date: '20.12.2022 18:30 Uhr',
          description: 'Allgemeines Verwaltungsrecht und Verwaltungsprozessrecht: Struktur und Überblick über allgemeines und besonderes Verwaltungsrecht',
          duration: '1:30:00',
          type: 'Vorlesung',
          image: 'https://www.tum-cdps.de/wp-content/uploads/lecturehall.jpg',
          disabled: true,
          file: 'https://www.tum-cdps.de/wp-content/uploads/VL8.pdf',
          location: "Hörsaal 2370",
          locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes"
        },
        {
          title: '9. Vorlesung',
          subtitle: '',
          date: '10.01.2023 18:30 Uhr',
          description: 'Allgemeines Verwaltungsrecht und Verwaltungsprozessrecht: Wirksamkeit und Rechtmäßigkeit des Verwaltungsakts',
          duration: '1:30:00',
          type: 'Vorlesung',
          image: 'https://www.tum-cdps.de/wp-content/uploads/lecturehall.jpg',
          disabled: true,
          file: 'https://www.tum-cdps.de/wp-content/uploads/VL9.pdf',
          location: "Hörsaal 2370",
          locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes"
        },
        {
          title: '10. Vorlesung',
          subtitle: '',
          date: '17.01.2023 18:30 Uhr',
          description: 'Allgemeines Verwaltungsrecht und Verwaltungsprozessrecht: Verwaltungsprozessrecht',
          duration: '1:30:00',
          type: 'Vorlesung',
          image: 'https://www.tum-cdps.de/wp-content/uploads/lecturehall.jpg',
          disabled: true,
          file: 'https://www.tum-cdps.de/wp-content/uploads/VL10.pdf',
          location: "Hörsaal 2370",
          locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes"
        },
        {
          title: '11. Vorlesung',
          subtitle: '',
          date: '24.01.2023 18:30 Uhr',
          description: 'Allgemeines Verwaltungsrecht und Verwaltungsprozessrecht: Weitere Handlungsformen der Verwaltung',
          duration: '1:30:00',
          type: 'Vorlesung',
          image: 'https://www.tum-cdps.de/wp-content/uploads/lecturehall.jpg',
          disabled: true,
          file: 'https://www.tum-cdps.de/wp-content/uploads/VL11.pdf',
          location: "Hörsaal 2370",
          locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes"
        },
        {
          title: '12. Vorlesung',
          subtitle: '',
          date: '31.01.2023 18:30 Uhr',
          description: 'Europarecht',
          duration: '1:30:00',
          type: 'Vorlesung',
          image: 'https://www.tum-cdps.de/wp-content/uploads/lecturehall.jpg',
          disabled: true,
          file: 'https://www.tum-cdps.de/wp-content/uploads/VL12.pdf',
          location: "Hörsaal 2370",
          locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes"
        },
        {
          title: '13. Vorlesung',
          subtitle: '',
          date: '07.02.2023 18:30 Uhr',
          description: 'Puffereinheit',
          duration: '1:30:00',
          type: 'Vorlesung',
          image: 'https://www.tum-cdps.de/wp-content/uploads/lecturehall.jpg',
          disabled: true,
          file: 'https://www.tum-cdps.de/wp-content/uploads/VL13.pdf',
          location: "Hörsaal 2370",
          locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes"
        },
      
      ],
      uebungen1: [
          {
          title: '1. Übung',
          subtitle: '',
          date: '26.10.2022 18:30 Uhr',
          description: '',
          duration: '1:30:00',
          type: 'Übung1',
          image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum2.jpg',
          disabled: true,
          file: 'https://www.tum-cdps.de/wp-content/uploads/UE1.pdf',
          location: "Hörsaal 2370",
          locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes"
        },
        {
          title: '2. Übung',
          subtitle: '',
          date: '02.11.2022 18:30 Uhr',
          description: '',
          duration: '1:30:00',
          type: 'Übung1',
          image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum2.jpg',
          disabled: true,
          file: 'https://www.tum-cdps.de/wp-content/uploads/UE2.pdf',
          location: "Hörsaal 2370",
          locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes"
        },
        {
          title: '3. Übung',
          subtitle: '',
          date: '09.11.2022 18:30 Uhr',
          description: '',
          duration: '1:30:00',
          type: 'Übung1',
          image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum2.jpg',
          disabled: true,
          file: 'https://www.tum-cdps.de/wp-content/uploads/UE3.pdf',
          location: "Hörsaal 2370",
          locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes"
        },
        {
          title: '4. Übung',
          subtitle: '',
          date: '16.11.2022 18:30 Uhr',
          description: '',
          duration: '1:30:00',
          type: 'Übung1',
          image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum2.jpg',
          disabled: true,
          file: 'https://www.tum-cdps.de/wp-content/uploads/UE4.pdf',
          location: "Hörsaal 2370",
          locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes"
        },
        {
          title: '5. Übung',
          subtitle: '',
          date: '23.11.2022 18:30 Uhr',
          description: '',
          duration: '1:30:00',
          type: 'Übung1',
          image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum2.jpg',
          disabled: true,
          file: 'https://www.tum-cdps.de/wp-content/uploads/UE5.pdf',
          location: "Hörsaal 2370",
          locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes"
        },
        {
          title: '6. Übung',
          subtitle: '',
          date: '30.11.2022 18:30 Uhr',
          description: '',
          duration: '1:30:00',
          type: 'Übung1',
          image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum2.jpg',
          disabled: true,
          file: 'https://www.tum-cdps.de/wp-content/uploads/UE6.pdf',
          location: "Hörsaal 2370",
          locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes"
        },
        {
          title: '7. Übung',
          subtitle: '',
          date: '07.12.2022 18:30 Uhr',
          description: '',
          duration: '1:30:00',
          type: 'Übung1',
          image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum2.jpg',
          disabled: true,
          file: 'https://www.tum-cdps.de/wp-content/uploads/UE7.pdf',
          location: "Hörsaal 2370",
          locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes"
        },
        {
          title: '8. Übung',
          subtitle: '',
          date: '14.12.2022 18:30 Uhr',
          description: '',
          duration: '1:30:00',
          type: 'Übung1',
          image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum2.jpg',
          disabled: true,
          file: 'https://www.tum-cdps.de/wp-content/uploads/UE8.pdf',
          location: "Hörsaal 2370",
          locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes"
        },
        {
          title: '9. Übung',
          subtitle: '',
          date: '21.12.2022 18:30 Uhr',
          description: '',
          duration: '1:30:00',
          type: 'Übung1',
          image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum2.jpg',
          disabled: true,
          file: 'https://www.tum-cdps.de/wp-content/uploads/UE9.pdf',
          location: "Hörsaal 2370",
          locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes"
        },
        {
          title: '10. Übung',
          subtitle: '',
          date: '11.01.2022 18:30 Uhr',
          description: '',
          duration: '1:30:00',
          type: 'Übung1',
          image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum2.jpg',
          disabled: true,
          file: 'https://www.tum-cdps.de/wp-content/uploads/UE10.pdf',
          location: "Hörsaal 2370",
          locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes"
        },
        {
          title: '11. Übung',
          subtitle: '',
          date: '18.01.2022 18:30 Uhr',
          description: '',
          duration: '1:30:00',
          type: 'Übung1',
          image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum2.jpg',
          disabled: true,
          file: 'https://www.tum-cdps.de/wp-content/uploads/UE11.pdf',
          location: "Hörsaal 2370",
          locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes"
        },
        {
          title: '12. Übung',
          subtitle: '',
          date: '25.01.2022 18:30 Uhr',
          description: '',
          duration: '1:30:00',
          type: 'Übung1',
          image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum2.jpg',
          disabled: true,
          file: 'https://www.tum-cdps.de/wp-content/uploads/UE12.pdf',
          location: "Hörsaal 2370",
          locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes"
        },
        {
          title: '13. Übung',
          subtitle: '',
          date: '01.02.2022 18:30 Uhr',
          description: '',
          duration: '1:30:00',
          type: 'Übung1',
          image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum2.jpg',
          disabled: true,
          file: 'https://www.tum-cdps.de/wp-content/uploads/UE13.pdf',
          location: "Hörsaal 2370",
          locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes"
        },
        {
          title: '14. Übung',
          subtitle: '',
          date: '08.02.2022 18:30 Uhr',
          description: '',
          duration: '1:30:00',
          type: 'Übung1',
          image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum2.jpg',
          disabled: true,
          file: 'https://www.tum-cdps.de/wp-content/uploads/UE14.pdf',
          location: "Hörsaal 2370",
          locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes"
        },
      ],
      uebungen2: [
        {
          title: '1. Übung',
          subtitle: '',
          date: '27.10.2022 18:30 Uhr',
          description: '',
          duration: '1:30:00',
          type: 'Übung2',
          image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum.jpg',
          file: 'https://www.tum-cdps.de/wp-content/uploads/UE1.pdf',
          disabled: true,
          location: "Seminarraum H.206",
          locationlink: "https://portal.mytum.de/displayRoomMap?roomid=H.206@2910&disable_decoration=yes"
        },
        {
          title: '2. Übung',
          subtitle: '',
          date: '03.11.2022 18:30 Uhr',
          description: '',
          duration: '1:30:00',
          type: 'Übung2',
          image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum.jpg',
          file: 'https://www.tum-cdps.de/wp-content/uploads/UE2.pdf',
          disabled: true,
          location: "Seminarraum H.206",
          locationlink: "https://portal.mytum.de/displayRoomMap?roomid=H.206@2910&disable_decoration=yes"
        },
        {
          title: '3. Übung',
          subtitle: '',
          date: '10.11.2022 18:30 Uhr',
          description: '',
          duration: '1:30:00',
          type: 'Übung2',
          image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum.jpg',
          file: 'https://www.tum-cdps.de/wp-content/uploads/UE3.pdf',
          disabled: true,
          location: "Seminarraum H.206",
          locationlink: "https://portal.mytum.de/displayRoomMap?roomid=H.206@2910&disable_decoration=yes"
        },
        {
          title: '4. Übung',
          subtitle: '',
          date: '17.11.2022 18:30 Uhr',
          description: '',
          duration: '1:30:00',
          type: 'Übung2',
          image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum.jpg',
          file: 'https://www.tum-cdps.de/wp-content/uploads/UE4.pdf',
          disabled: true,
          location: "Seminarraum H.206",
          locationlink: "https://portal.mytum.de/displayRoomMap?roomid=H.206@2910&disable_decoration=yes"
        },
        {
          title: '5. Übung',
          subtitle: '',
          date: '24.11.2022 18:30 Uhr',
          description: '',
          duration: '1:30:00',
          type: 'Übung2',
          image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum.jpg',
          file: 'https://www.tum-cdps.de/wp-content/uploads/UE5.pdf',
          disabled: true,
          location: "Seminarraum H.206",
          locationlink: "https://portal.mytum.de/displayRoomMap?roomid=H.206@2910&disable_decoration=yes"
        },
        {
          title: '6. Übung',
          subtitle: '',
          date: '01.12.2022 18:30 Uhr',
          description: '',
          duration: '1:30:00',
          type: 'Übung2',
          image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum.jpg',
          file: 'https://www.tum-cdps.de/wp-content/uploads/UE6.pdf',
          disabled: true,
          location: "Seminarraum H.206",
          locationlink: "https://portal.mytum.de/displayRoomMap?roomid=H.206@2910&disable_decoration=yes"
        },
        {
          title: '7. Übung',
          subtitle: '',
          date: '08.12.2022 18:30 Uhr',
          description: '',
          duration: '1:30:00',
          type: 'Übung2',
          image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum.jpg',
          file: 'https://www.tum-cdps.de/wp-content/uploads/UE7.pdf',
          disabled: true,
          location: "Seminarraum H.206",
          locationlink: "https://portal.mytum.de/displayRoomMap?roomid=H.206@2910&disable_decoration=yes"
        },
        {
          title: '8. Übung',
          subtitle: '',
          date: '15.12.2022 18:30 Uhr',
          description: '',
          duration: '1:30:00',
          type: 'Übung2',
          image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum.jpg',
          file: 'https://www.tum-cdps.de/wp-content/uploads/UE8.pdf',
          disabled: true,
          location: "Seminarraum H.206",
          locationlink: "https://portal.mytum.de/displayRoomMap?roomid=H.206@2910&disable_decoration=yes"
        },
        {
          title: '9. Übung',
          subtitle: '',
          date: '22.12.2022 18:30 Uhr',
          description: '',
          duration: '1:30:00',
          type: 'Übung2',
          image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum.jpg',
          file: 'https://www.tum-cdps.de/wp-content/uploads/UE9.pdf',
          disabled: true,
          location: "Seminarraum H.206",
          locationlink: "https://portal.mytum.de/displayRoomMap?roomid=H.206@2910&disable_decoration=yes"
        },
        {
          title: '10. Übung',
          subtitle: '',
          date: '12.01.2022 18:30 Uhr',
          description: '',
          duration: '1:30:00',
          type: 'Übung2',
          image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum.jpg',
          file: 'https://www.tum-cdps.de/wp-content/uploads/UE10.pdf',
          disabled: true,
          location: "Seminarraum H.206",
          locationlink: "https://portal.mytum.de/displayRoomMap?roomid=H.206@2910&disable_decoration=yes"
        },
        {
          title: '11. Übung',
          subtitle: '',
          date: '19.01.2023 18:30 Uhr',
          description: '',
          duration: '1:30:00',
          type: 'Übung2',
          image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum.jpg',
          file: 'https://www.tum-cdps.de/wp-content/uploads/UE11.pdf',
          disabled: true,
          location: "Seminarraum H.206",
          locationlink: "https://portal.mytum.de/displayRoomMap?roomid=H.206@2910&disable_decoration=yes"
        },
        {
          title: '12. Übung',
          subtitle: '',
          date: '26.01.2023 18:30 Uhr',
          description: '',
          duration: '1:30:00',
          type: 'Übung2',
          image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum.jpg',
          file: 'https://www.tum-cdps.de/wp-content/uploads/UE12.pdf',
          disabled: true,
          location: "Seminarraum H.206",
          locationlink: "https://portal.mytum.de/displayRoomMap?roomid=H.206@2910&disable_decoration=yes"
        },
        {
          title: '13. Übung',
          subtitle: '',
          date: '02.02.2023 18:30 Uhr',
          description: '',
          duration: '1:30:00',
          type: 'Übung2',
          image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum.jpg',
          file: 'https://www.tum-cdps.de/wp-content/uploads/UE13.pdf',
          disabled: true,
          location: "Seminarraum H.206",
          locationlink: "https://portal.mytum.de/displayRoomMap?roomid=H.206@2910&disable_decoration=yes"
        },
        {
          title: '14. Übung',
          subtitle: '',
          date: '09.02.2023 18:30 Uhr',
          description: '',
          duration: '1:30:00',
          type: 'Übung2',
          image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum.jpg',
          file: 'https://www.tum-cdps.de/wp-content/uploads/UE14.pdf',
          disabled: true,
          location: "Seminarraum H.206",
          locationlink: "https://portal.mytum.de/displayRoomMap?roomid=H.206@2910&disable_decoration=yes"
        },
      ],
    }),
    computed: {
      filteredVideos: function() {
        return this.vorlesungen 
      },
      filteredUnits: function() {
        if (this.vl_filter == true) { 
          return this.vorlesungen
        }
        else if (this.ue1_filter == true) {
          return this.uebungen1
        } 
        else if (this.ue2_filter == true) {
          return this.uebungen2
        }
        else {
          return this.vorlesungen
        }
      }
    },
  }
</script>

<style scoped>
.center {
  margin: auto;
}
</style>