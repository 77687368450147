<template>
  <div id="inspire">
    <v-app-bar elevation="0" color="grey lighten-5">
      <!-- Grouping Computational Law Blog and its description -->
      <div
        :class="['pa-15', {'active': activeDiv === 'Computational Law Blog'}]"
        @click="setActiveDiv('Computational Law Blog')"
      >
        Computational Law Blog
        <span class="blue--text ml-1"><strong>/</strong></span>
        <span class="grey--text text--lighten-1 ml-1">
          Thoughts on Computational Law, Technology Law & Policy, Legal Theory and Constitutional Law
        </span>
      </div>

      <!-- Other menu items -->
      <div
        :class="['pl-5 pr-5', {'active': activeDiv === 'Team'}]"
        @click="setActiveDiv('Team')"
      >Team</div>
      <div
        :class="['pl-5 pr-5', {'active': activeDiv === 'Teaching'}]"
        @click="setActiveDiv('Teaching')"
      >Teaching</div>
      <div
        :class="['pl-5 pr-5', {'active': activeDiv === 'Projects'}]"
        @click="setActiveDiv('Projects')"
      >Projects</div>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: 'AppBar',
  data: () => ({
    // ... existing data properties
    activeDiv: 'Computational Law Blog', // default active item
  }),
  methods: {
    setActiveDiv(divTitle) {
      this.activeDiv = divTitle;
    },
  },
  // ... existing methods, watch, etc.
}
</script>

<style scoped>
.stick {
  position: sticky;
  top:  100px;
  z-index:  2;
}

.active {
  position: relative; /* Establish a positioning context for the pseudo-element */
}

.active::after {
  content: ''; /* Required for pseudo-elements */
  display: block; /* Makes the pseudo-element behave like a block */
  position: absolute; /* Position relative to the .active div */
  bottom: -20px; /* Adjust this value to move the underline lower */
  left: 0; /* Aligns the underline to the left of the container */
  width: 100%; /* Ensure the underline spans the full width of the container */
  height: 2px; /* Height of the underline */
  background-color: black; /* Color of the underline */
}
</style>
