<template>
  <div>
    <v-footer
    padless
    class="grey lighten-5"
  >
  <v-card
  elevation="0"
  class="grey lighten-4 mt-10 center"
  width="500"
>
<v-card-title class="grey--text text--darken-1 text-subtitle-1">Computational Law Blog<div class="blue--text ml-1"><strong>/</strong></div></v-card-title>
<v-card-subtitle class="pt-4 pb-7">Thoughts on Computational Law, Technology Law & Policy, Legal Theory and Constitutional Law by <a href="https://twitter.com/mibressler">@mibressler</a> and friends.</v-card-subtitle>

         </v-card>
    <v-card
      flat
      tile
      class="grey lighten-5 text-center"
      width="100%"
    >
      <v-card-text>
        <v-btn
          v-for="icon in icons"
          :key="icon"
          class="mx-4"
          icon
          :href="icon.destination"
          target="_blank"
        >
          <v-icon size="24px">
            {{ icon.icon }}
          </v-icon>
        </v-btn>
      </v-card-text>

  

       <v-card-text class="pt-0">
        About | Submit Article | Imprint | Privacy Policy
      </v-card-text>

      <v-card-text class="">
        {{ new Date().getFullYear() }} — <strong>Computational Law Blog</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</div>
</template>

<script>
  export default {
    data: () => ({
      icons: [

       {icon: 'mdi-twitter', destination: 'https://twitter.com/computationlaw'},
       {icon: 'mdi-github', destination: 'https://github.com/complaw'},
       {icon: 'mdi-school', destination: 'https://researchblog.org/complaw'},
      ],
    }),
  }
</script>

<style>
.center {
  margin: auto;
}
a {
  color: #2196f3;
  text-decoration: none;
}
</style>