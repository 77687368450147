<template>
  <div id="app">

    <Blog/>

  </div>
</template>
<script>
import Blog from "@/views/Blog.vue";
export default {
  name: 'App',
  components: {
    Blog,

  }
}
</script>


