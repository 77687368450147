<template>
    <div id="app">
      <v-app id="inspire">
        <AppBar/>
      <router-view/>
        <FooterBar/>
  
      </v-app>
    </div>
  </template>
  
  <script>
  import AppBar from "@/components/AppBar.vue";
  import FooterBar from "@/components/FooterBar.vue";
  export default {
    name: 'App',
    components: {
        FooterBar,
        AppBar

  
    }
  }
  </script>
  
  
  